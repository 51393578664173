import { Component, OnInit, EventEmitter, Output, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { DxDataGridComponent, DxDropDownBoxComponent } from "devextreme-angular";
import { SiteService } from 'app/services/site.service';
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from 'app/services/user.service';
import CheckBox from "devextreme/ui/check_box";
import { filter } from 'lodash';
import { on } from "devextreme/events";
import { AppConfig } from 'app/app.config';

@Component({
  selector: 'app-sites-selecteur',
  templateUrl: './sites-selecteur.component.html',
  styleUrls: ['./sites-selecteur.component.scss']
})
export class SitesSelecteurComponent implements OnInit, OnChanges {

  @ViewChild('dropBox') dropBox: DxDropDownBoxComponent;
  @ViewChild('siteGrid') siteGrid: DxDataGridComponent;
  @Output() siteUpdated = new EventEmitter();
  @Input('selectedClients') selectedClients: any = [];
  @Input('selectedSite') selectedSite: any = [];
  @Input('profilFilter') profilFilter: any = undefined;
  @Input('defaultMessage') nomSite: any = "Choisir un site";


  // datasources
  siteDataSource: any;

  selectedKeys_ = []
  selected_ = []

  constructor(private siteService: SiteService, public translate: TranslateService) {
    this.siteDataSource = siteService.getRestDataSource();

    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
    this.translate.get(
      [
        'site-selecteur-placeholder*nomSite'

      ]
    )
      .subscribe(val => {

        this.nomSite = val['site-selecteur-placeholder*nomSite'];

      });

  }

  ngOnInit() {
    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
  }


  reset() {
  }


  ngAfterViewInit() {
    // Trick for enabling site refreshing when changing client for the very first time
    this.dropBox.instance.open();
    this.dropBox.instance.close();
  }


  public update() {
    if (!this.profilFilter)
      this.siteGrid.instance.filter([this.getFilterOnClients(this.selectedClients), "AND", ["actif", "=", "1"]]);
    else
      this.siteGrid.instance.filter([this.getFilterOnClients(this.selectedClients), "AND", [["profil_id", "=", this.profilFilter], "OR", ["profil_id", "IS", "NULL"]], "AND", ["actif", "=", "1"]]);

    this.siteGrid.instance.getDataSource().reload();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (AppConfig.settings.trace) console.log(`=== onChange`)
    this.nomSite = "";

    if (changes.selectedClients && this.selectedClients.length > 0) {

      if (this.siteGrid && this.siteGrid.instance) {
        if (!this.profilFilter)
          this.siteGrid.instance.filter([this.getFilterOnClients(this.selectedClients), "AND", ["actif", "=", "1"]]);
        else
          this.siteGrid.instance.filter([this.getFilterOnClients(this.selectedClients), "AND", [["profil_id", "=", this.profilFilter], "OR", ["profil_id", "IS", "NULL"]], "AND", ["actif", "=", "1"]]);

        //this.siteGrid.instance.getDataSource().reload();
      }
    }

    if (changes.profilFilter) {

      if (this.siteGrid && this.siteGrid.instance) {
        if (!this.profilFilter)
          this.siteGrid.instance.filter([this.getFilterOnClients(this.selectedClients), "AND", ["actif", "=", "1"]]);
        else
          this.siteGrid.instance.filter([this.getFilterOnClients(this.selectedClients), "AND", [["profil_id", "=", this.profilFilter], "OR", ["profil_id", "IS", "NULL"]], "AND", ["actif", "=", "1"]]);

        this.siteGrid.instance.getDataSource().reload();
      }
    }

    if (changes.defaultMessage)
      this.nomSite = changes.defaultMessage;

  }

  dropdown_syncGridSelection(event) {

    if (!this.siteGrid) return;

    if (event.value === null) {
      if (AppConfig.settings.trace) console.log(`=== clear`)
      this.nomSite = "";
      this.siteGrid.instance.clearSelection();
    }
  }

  dropdown_gridItemSelectionChanged(event) {
    if (AppConfig.settings.trace) console.log(`=== selectionChanged`)
    let selected = []
    let selectedKeys = []
    event.component.getSelectedRowsData().done(data => {
      selected = data
      this.selected_ = data
      for (let site of data) {
        selectedKeys.push(site.id)
      }
      this.selectedKeys_ = selectedKeys
      this.siteUpdated.emit(selected);
    })
  }

  gridBox_displayExpr(item) {
    return item && item.nom;
  }

  getFilterOnClients(clients: any) {
    let filter = []
    clients.forEach(cl => {
      filter.push(["client_id", "=", cl])
      filter.push("OR")
    })
    filter.pop()
    return filter;
  }

  sitesGrid_ContentReady(e: any) {
    let tabs = document.getElementsByClassName('dx-header-row')
    for (let idx = 0; idx < tabs.length; idx++) {
      let tab = tabs[idx]
      let checkbox = tab.firstElementChild.firstElementChild
      checkbox.addEventListener('click', e => {
        if (AppConfig.settings.trace) console.log(`=== selectAll`)
        let checked_ = checkbox.firstElementChild
        let checked = checked_.getAttribute('value')
        if (checked == 'true') {
          let selectedKeys = []
          let selected = []
          let sites
          this.siteGrid.instance.getDataSource().store().load().done((res: any) => {
            sites = res.data
            for (let site of sites) {
              selectedKeys.push(site.id)
              selected.push(site)
            }
            this.siteGrid.selectedRowKeys = selectedKeys
            this.selectedKeys_ = selectedKeys
            this.selected_ = selected
            this.siteUpdated.emit(selected)
          })

        } else {
          this.siteGrid.selectedRowKeys = []
          this.selected_ = []
        }
      })
    }
  }


}

